import { Stat } from "./character";

export const EnduranceCurrentStat: Stat = {
    id: "enduranceCurrent",
    name: "Current Endurance"
}

export const EnduranceMaxStat: Stat = {
    id: "enduranceMax",
    name: "Max Endurance"
}

export const EquipmentCurrentStat: Stat = {
    id: "equipmentCurrent", name: "Current Equipment"
}

export const EquipmentMaxStat: Stat = {
    id: "equipmentMax", name: "Max Equipment"
}

export const ArmorStat: Stat = {
    id: "armor", name: "Armor"
}

export const ArmorAtZeroStat: Stat = {
    id: "armorAtZero", name: "Armor at 0 Endurance"
}

export const HitBonus: Stat = {
    id: "hitBonus", name: "Hit Bonus"
}

export const EnduranceDamage: Stat = {
    id: "enduranceDamage", name: "Endurance Damage"
}

export const InfluenceMax: Stat = {
    id: "influenceMax", name: "Influence Max"
}

export const ResourcesMax: Stat = {
    id: "resourcesMax", name: "Resources Max"
}

export const UtilitiesMax: Stat = {
    id: "utilitiesMax", name: "Utilities Max"
}

export const GritReroll: Stat = {
    id: "gritReroll", name: "Grit Reroll"
}

export const AugmentedNumber: Stat = {
    id: "augmentedNumber", name: "Augmented #"
}

export const MaximumAttributePoints: Stat = {
    id: "maximumAttributePoints", name: "Maximum Attribute Points"
}

export const Recovery : Stat = {
    id: "recovery", name: "Recovery"
}

export const Resistance : Stat = {
    id: "resistance", name: "Resistance"
}

export const CombatOrder : Stat = {
    id: "combatOrder", name: "Combat Order"
}

export const Defence : Stat = {
    id: "defence", name: "Defence"
}

export const Movement : Stat = {
    id: "movement", name: "Movement"
}

export const AcquisitionRolls : Stat = {
    id: "acquisitionRolls", name: "Acquisition Rolls"
}

export const ArcaneRolls : Stat = {
    id: "arcaneRolls", name: "Arcane Rolls"
}

export const AllStats = [
    EnduranceCurrentStat,
    EnduranceMaxStat,
    EquipmentCurrentStat,
    EquipmentMaxStat,
    ArmorStat,
    ArmorAtZeroStat,
    HitBonus,
    EnduranceDamage,
    InfluenceMax,
    ResourcesMax,
    UtilitiesMax,
    GritReroll,
    AugmentedNumber,
    MaximumAttributePoints,
    Recovery,
    Resistance,
    CombatOrder,
    Defence,
    Movement,
    AcquisitionRolls,
    ArcaneRolls,
]
