import { Button, Dialog, DialogContent, DialogTitle, Grid, GridSize, Stack, Typography } from "@mui/material";
import Section from "../../../components/Section";
import SimpleTable from "../../../components/SimpleTable";
import { EquipmentBase, Outfit, PlayerCharacter, PlayerCharacterDisplay, TradeGood, Equipment, Weapon } from "../../../model/character";
import { ReactNode, useState } from "react";
import TradeGoodEditor, { GetNewTradeGood } from "./TradeGoodEditor";
import { GetNewOutfit, OutfitEditor } from "./OutfitsEditor";
import { GetNewWeapon, WeaponEditor } from "./WeaponsEditor";
import { GetNewUtility, UtilityEditor } from "./UtilityEditor";

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import EquipmentBagIcon from "../../../components/icons/EquipmentBagIcon";
import MiscIcon from "../../../components/icons/MiscIcon";
import OutfitIcon from "../../../components/icons/OutfitIcon";
import TradeGoodIcon from "../../../components/icons/TradeGoodIcon";
import UtilityIcon from "../../../components/icons/UtilityIcon";
import WeaponIcon from "../../../components/icons/WeaponIcon";
import { EquipmentEditor, GetNewEquipment } from "./EquipmentEditor";
import HeadingBar, { HeadingBarHeader } from "../../../components/HeadingBar";
import StashedIcon from "../../../components/icons/Stashed";
import WeaponDetailDisplay from "../../../components/displays/WeaponDetailDisplay";
import OutfitDetailDisplay from "../../../components/displays/OutfitDetailDisplay";
import CollapseClickAway from "../../../components/CollapseClickAway";


interface EquipmentRow {
    icon: ReactNode,
    name: ReactNode,
    amount?: number,
    slots?: number,
    influence?: number,
    resources?: number,
    knowledge?: number,
    actions: ReactNode,
    details?: ReactNode, //For extra details in the drop down
}

interface EquipmentListProps {
    character: PlayerCharacter;
    characterDisplay: PlayerCharacterDisplay;
    setCharacter: (value: PlayerCharacter) => void
}

const EquipmentList = (props: EquipmentListProps) => {
    const { character, characterDisplay, setCharacter } = props;

    const [ editTradeGood, setEditTradeGood ] = useState<TradeGood>();
    const [ editWeapon, setEditWeapon ] = useState<Weapon>();
    const [ manageWeapon, setManageWeapon ] = useState<Weapon>();
    const [ editOutfit, setEditOutfit ] = useState<Outfit>();
    const [ manageOutfit, setManageOutfit ] = useState<Outfit>();
    const [ editUtility, setEditUtility ] = useState<Equipment>();
    const [ editEquipment, setEditEquipment ] = useState<Equipment>();

    const [ showAddModal, setShowAddModal ] = useState(false);

    const handleAddTradeGood = () => {
        setEditTradeGood(GetNewTradeGood());
        setShowAddModal(false);
    }

    const saveTradeGood = (tradeGood: TradeGood) => {
        const index = character.tradeGoods.findIndex(tg => tg.id === tradeGood.id);
        const newTradeGoods = [...character.tradeGoods];
        console.log(index);
        if(index === -1) {
            newTradeGoods.push(tradeGood);
        } else {
            newTradeGoods[index] = tradeGood;
        }
        setCharacter({
            ...character,
            tradeGoods: newTradeGoods
        })
        setEditTradeGood(undefined);
    }

    const handleEditTradeGood = (key: string) => {
        const tradeGood = character.tradeGoods.find(tg => tg.id === key);
        setEditTradeGood(tradeGood);
    }

    const handleRemoveTradeGood = (key: string) => {
        const index = character.tradeGoods.findIndex(tg => tg.id === key);
        if(index !== undefined){
            const newTradeGoods = [...character.tradeGoods]
            newTradeGoods.splice(index,1);
            setCharacter({
                ...character,
                tradeGoods: newTradeGoods
            })
        }
    }

    const handleAddWeapon = () => {
        setEditWeapon(GetNewWeapon());
        setShowAddModal(false);
    }

    const saveWeapon = (weapon: Weapon) => {
        const index = character.weapons.findIndex(tg => tg.id === weapon.id);
        const newItems = [...character.weapons];
        console.log(index);
        if(index === -1) {
            newItems.push(weapon);
        } else {
            newItems[index] = weapon;
        }
        setCharacter({
            ...character,
            weapons: newItems
        })
        setEditWeapon(undefined);
    }

    const handleEditWeapon = (key: string) => {
        const weapon = character.weapons.find(tg => tg.id === key);
        setEditWeapon(weapon);
    }

    const handleRemoveWeapon = (key: string) => {
        const index = character.weapons.findIndex(i => i.id === key);
        if(index !== undefined){
            const newItems = [...character.weapons]
            newItems.splice(index,1);
            setCharacter({
                ...character,
                weapons: newItems
            })
        }
    }

    const onChangeWeapon = (id: string, key: string, value: any) => {
        const index = character.weapons.findIndex(w => w.id === id);
        saveWeapon({
            ...character.weapons[index],
            [key]: value
        })
    }

    const handleAddOutfit = () => {
        setEditOutfit(GetNewOutfit());
        setShowAddModal(false);
    }

    const saveOutfit = (outfit: Outfit) => {
        const index = character.outfits.findIndex(tg => tg.id === outfit.id);
        const newItems = [...character.outfits];
        console.log(index);
        if(index === -1) {
            newItems.push(outfit);
        } else {
            newItems[index] = outfit;
        }
        setCharacter({
            ...character,
            outfits: newItems
        })
        setEditOutfit(undefined);
    }

    const handleEditOutfit = (key: string) => {
        const outfit = character.outfits.find(a => a.id === key);
        setEditOutfit(outfit);
    }

    const handleRemoveOutfit = (key: string) => {
        const index = character.outfits.findIndex(tg => tg.id === key);
        if(index !== undefined){
            const newItems = [...character.outfits]
            newItems.splice(index,1);
            setCharacter({
                ...character,
                outfits: newItems
            })
        }
    }

    const onChangeOutfit = (id: string, key: string, value: any) => {
        const index = character.outfits.findIndex(w => w.id === id);
        saveOutfit({
            ...character.outfits[index],
            [key]: value
        })
    }

    const handleAddUtility = () => {
        setEditUtility(GetNewUtility());
        setShowAddModal(false);
    }

    const saveUtility = (utility: Equipment) => {
        const index = character.utilities.findIndex(tg => tg.id === utility.id);
        const newItems = [...character.utilities];
        console.log(index);
        if(index === -1) {
            newItems.push(utility);
        } else {
            newItems[index] = utility;
        }
        setCharacter({
            ...character,
            utilities: newItems
        })
        setEditUtility(undefined);
    }

    const handleEditUtility = (key: string) => {
        const utility = character.utilities.find(a => a.id === key);
        setEditUtility(utility);
    }

    const handleRemoveUtility = (key: string) => {
        const index = character.utilities.findIndex(tg => tg.id === key);
        if(index !== undefined){
            const newItems = [...character.utilities]
            newItems.splice(index,1);
            setCharacter({
                ...character,
                utilities: newItems
            })
        }
    }

    const onChangeUtility = (id: string, key: string, value: any) => {
        const index = character.utilities.findIndex(w => w.id === id);
        saveUtility({
            ...character.utilities[index],
            [key]: value
        })
    }

    const handleAddEquipment = () => {
        setEditEquipment(GetNewUtility("New Equipment"));
        setShowAddModal(false);
    }

    const saveEquipment = (equipment: Equipment) => {
        const index = character.equipment.findIndex(tg => tg.id === equipment.id);
        const newItems = [...character.equipment];
        console.log(index);
        if(index === -1) {
            newItems.push(equipment);
        } else {
            newItems[index] = equipment;
        }
        setCharacter({
            ...character,
            equipment: newItems
        })
        setEditEquipment(undefined);
    }

    const handleEditEquipment = (key: string) => {
        const equipment = character.equipment.find(a => a.id === key);
        setEditEquipment(equipment);
    }

    const handleRemoveEquipment = (key: string) => {
        const index = character.equipment.findIndex(tg => tg.id === key);
        if(index !== undefined){
            const newItems = [...character.equipment]
            newItems.splice(index,1);
            setCharacter({
                ...character,
                utilities: newItems
            })
        }
    }

    const onChangeEquipment = (id: string, key: string, value: any) => {
        const index = character.equipment.findIndex(w => w.id === id);
        saveEquipment({
            ...character.equipment[index],
            [key]: value
        })
    }

    const tableList: EquipmentRow[] = [];

    for(const tradeGood of character.tradeGoods) {
        tableList.push({
            icon: <TradeGoodIcon />,
            name: tradeGood.name,
            amount: tradeGood.amount,
            slots: tradeGood.amount * tradeGood.weight,
            actions: <>
                <EditIcon style={{ cursor: "pointer"}} onClick={() => handleEditTradeGood(tradeGood.id)} />
                <CancelIcon style={{ cursor: "pointer"}} onClick={() => handleRemoveTradeGood(tradeGood.id)} />
            </>,
            details: <Grid container marginTop={2}>
                <Grid item xs={12}>{tradeGood.description}</Grid>
                <Grid item xs={8} md={4}>Weight:</Grid>
                <Grid item xs={4} md={2}>{tradeGood.weight}</Grid>
                <Grid item xs={8} md={4}>Slot Conversion:</Grid>
                <Grid item xs={4} md={2}>{tradeGood.conversion}</Grid>
            </Grid>
        })
    }

    const handSlots = character.weapons.filter(w => w.isHeld).length;
    const wornSlots = character.outfits.filter(w => w.isWorn).length;

    for(const weapon of characterDisplay.weapons) {
        const mainWeapon = character.weapons.find(w => w.id === weapon.id);
        tableList.push({
            icon: <WeaponIcon />,
            name: <Stack alignItems="center" direction="row">{weapon.name} {weapon.isHeld && <EquipmentBagIcon />}{weapon.isStashed && <StashedIcon />}</Stack>,
            actions: <>
                <SettingsIcon style={{ cursor: "pointer"}} onClick={() => setManageWeapon(mainWeapon)} />
                <EditIcon style={{ cursor: "pointer"}} onClick={() => handleEditWeapon(weapon.id)} />
                <CancelIcon style={{ cursor: "pointer"}} onClick={() => handleRemoveWeapon(weapon.id)} />
            </>,
            influence: weapon.influence,
            knowledge: weapon.knowledge,
            resources: weapon.resources,
            slots: weapon.slots,
            details: <WeaponDetailDisplay weapon={mainWeapon!} weaponDisplay={weapon} />
        })
    }

    for(const outfit of characterDisplay.outfits) {
        const mainOutfit = character.outfits.find(w => w.id === outfit.id);
        tableList.push({
            icon: <OutfitIcon />,
            name: <>{outfit.name} {outfit.isWorn && <EquipmentBagIcon />}{outfit.isStashed && <StashedIcon />}</>,
            actions: <>
                <SettingsIcon style={{ cursor: "pointer"}} onClick={() => setManageOutfit(mainOutfit)} />
                <EditIcon style={{ cursor: "pointer"}} onClick={() => handleEditOutfit(outfit.id)} />
                <CancelIcon style={{ cursor: "pointer"}} onClick={() => handleRemoveOutfit(outfit.id)} />
            </>,
            influence: outfit.influence,
            knowledge: outfit.knowledge,
            resources: outfit.resources,
            slots: outfit.slots,
            details: <OutfitDetailDisplay outfit={mainOutfit!} outfitDisplay={outfit} />
        })
    }

    for(const utility of characterDisplay.utility) {
        let action = <></>;
        if(!utility.isAcquired) {
            action = <Button size="small" variant="contained" onClick={() => onChangeUtility(utility.id, "isAcquired", true)}>Acquire</Button>
        }
        tableList.push({
            icon: <UtilityIcon />,
            name: utility.name,
            slots: utility.slots,
            influence: utility.influence,
            knowledge: utility.knowledge,
            resources: utility.resources,
            actions: <>
                <EditIcon style={{ cursor: "pointer"}} onClick={() => handleEditUtility(utility.id)} />
                <CancelIcon style={{ cursor: "pointer"}} onClick={() => handleRemoveUtility(utility.id)} />
            </>,
            details: <Grid container marginTop={2}>
            <Grid item xs={12}>{utility.description}</Grid>
            <Grid item xs={8}>Actions:</Grid>
            <Grid item xs={4}>{action}</Grid>
            <Grid item xs={8}>Features:</Grid>
            <Grid item xs={4}>{utility.features.length === 0 ? "None" : utility.features.join(", ")}</Grid>
        </Grid>
        })
    }

    for(const equipment of characterDisplay.equipment) {
        let action = <></>;
        if(!equipment.isAcquired) {
            action = <Button size="small" variant="contained" onClick={() => onChangeEquipment(equipment.id, "isAcquired", true)}>Acquire</Button>
        }
        tableList.push({
            icon: <MiscIcon />,
            name: equipment.name,
            slots: equipment.slots,
            influence: equipment.influence,
            knowledge: equipment.knowledge,
            resources: equipment.resources,
            actions: <>
                <EditIcon style={{ cursor: "pointer"}} onClick={() => handleEditEquipment(equipment.id)} />
                <CancelIcon style={{ cursor: "pointer"}} onClick={() => handleRemoveEquipment(equipment.id)} />
            </>,
            details: <Grid container marginTop={2}>
            <Grid item xs={12}>{equipment.description}</Grid>
            <Grid item xs={8}>Actions:</Grid>
            <Grid item xs={4}>{action}</Grid>
            <Grid item xs={8}>Features:</Grid>
            <Grid item xs={4}>{equipment.features.length === 0 ? "None" : equipment.features.join(", ")}</Grid>
        </Grid>
        })
    }

    const ManageWeaponDialogue = () => {
        let action = <></>;
        if(manageWeapon !== undefined){
            action = <Stack direction="row" spacing={1}>
                {manageWeapon.isAcquired && manageWeapon.isHeld && <Button size="small" variant="contained" onClick={() => {
                        setManageWeapon(undefined);
                        onChangeWeapon(manageWeapon.id, "isHeld", false)
                    }}>Stow Weapon</Button>}
                {manageWeapon.isAcquired && !manageWeapon.isHeld && <Button size="small" variant="contained" onClick={() => {
                        setManageWeapon(undefined);
                        onChangeWeapon(manageWeapon.id, "isHeld", true)
                    }} disabled={handSlots >= 2}>Draw Weapon</Button>}
                {manageWeapon.isAcquired && manageWeapon.isStashed && <Button size="small" variant="contained" onClick={() => {
                        setManageWeapon(undefined);
                        onChangeWeapon(manageWeapon.id, "isStashed", false)
                    }}>Unstore Wepaon</Button>}
                {manageWeapon.isAcquired && !manageWeapon.isStashed && <Button size="small" variant="contained" onClick={() => {
                        setManageWeapon(undefined);
                        onChangeWeapon(manageWeapon.id, "isStashed", true)
                    }}>Store Weapon</Button>}
                {!manageWeapon.isAcquired && <Button size="small" variant="contained" onClick={() => {
                    setManageWeapon(undefined);
                    onChangeWeapon(manageWeapon.id, "isAcquired", true)
                }}>Acquire Weapon</Button>}
            </Stack>
        }
        return <Dialog open={manageWeapon !== undefined} onClose={() => setManageWeapon(undefined)}>
            <DialogTitle>Manage weapon state</DialogTitle>
            <DialogContent>
                {action}
            </DialogContent>
        </Dialog>
    }

    const ManageOutfitDialogue = () => {
        let action = <></>;
        if(manageOutfit !== undefined){
            action = <Stack direction="row" spacing={1}>
                {manageOutfit.isAcquired && manageOutfit.isWorn && <Button size="small" variant="contained" onClick={() => {
                        setManageOutfit(undefined);
                        onChangeOutfit(manageOutfit.id, "isWorn", false)
                    }}>Stow Outfit</Button>}
                {manageOutfit.isAcquired && !manageOutfit.isWorn && <Button size="small" variant="contained" onClick={() => {
                        setManageOutfit(undefined);
                        onChangeOutfit(manageOutfit.id, "isWorn", true)
                    }} disabled={wornSlots >= 1}>Wear Outfit</Button>}
                {manageOutfit.isAcquired && manageOutfit.isStashed && <Button size="small" variant="contained" onClick={() => {
                        setManageOutfit(undefined);
                        onChangeOutfit(manageOutfit.id, "isStashed", false)
                    }}>Unstore Outfit</Button>}
                {manageOutfit.isAcquired && !manageOutfit.isStashed && <Button size="small" variant="contained" onClick={() => {
                        setManageOutfit(undefined);
                        onChangeOutfit(manageOutfit.id, "isStashed", true)
                    }}>Store Outfit</Button>}
                {!manageOutfit.isAcquired && <Button size="small" variant="contained" onClick={() => {
                    setManageOutfit(undefined);
                    onChangeOutfit(manageOutfit.id, "isAcquired", true)
                }}>Acquire Outfit</Button>}
            </Stack>
        }
        return <Dialog open={manageOutfit !== undefined} onClose={() => setManageOutfit(undefined)}>
            <DialogTitle>Manage outfit state</DialogTitle>
            <DialogContent>
                {action}
            </DialogContent>
        </Dialog>
    }
    const GridRow = (props: { gridSize: GridSize, justifyContent?: "center" | "flex-start" | "flex-end", children?: ReactNode }) => {
        return <Grid item xs={props.gridSize} ><Stack direction="row" justifyContent={props.justifyContent} alignItems="center" height="100%">{props.children}</Stack></Grid>
    }

    return <Section
        title={`Items ${characterDisplay.equipmentSlotsCurrent}/${characterDisplay.equipmentSlots}`}
        actions={ [
            { action: () => setShowAddModal(true), label: <AddIcon /> }
        ]}>
        <Dialog open={showAddModal} onClose={() => setShowAddModal(false)}>
            <DialogTitle>Add Equipment</DialogTitle>
            <DialogContent>
                <Button onClick={handleAddWeapon}>Weapon</Button>
                <Button onClick={handleAddOutfit}>Outfit</Button>
                <Button onClick={handleAddUtility}>Utility</Button>
                <Button onClick={handleAddTradeGood}>Trade Good</Button>
                <Button onClick={handleAddEquipment}>Misc</Button>
            </DialogContent>
        </Dialog>
        <ManageWeaponDialogue />
        <ManageOutfitDialogue />
        <TradeGoodEditor setTradeGood={saveTradeGood} tradeGood={editTradeGood} onClose={() => setEditTradeGood(undefined)} />
        <WeaponEditor setWeapon={saveWeapon} weapon={editWeapon} onClose={() => setEditWeapon(undefined)} character={character}  />
        <OutfitEditor setOutfit={saveOutfit} outfit={editOutfit} onClose={() => setEditOutfit(undefined)} character={character}  />
        <UtilityEditor setUtility={saveUtility} utility={editUtility} onClose={() => setEditUtility(undefined)} character={character}  />
        <UtilityEditor setUtility={saveEquipment} utility={editEquipment} onClose={() => setEditEquipment(undefined)} character={character}  />

        <HeadingBar>
            <Grid container paddingX={1}>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}><HeadingBarHeader>Name</HeadingBarHeader></Grid>
                <Grid item xs={1}><HeadingBarHeader tooltip="Slots">S</HeadingBarHeader></Grid>
                <Grid item xs={1}><HeadingBarHeader>I</HeadingBarHeader></Grid>
                <Grid item xs={1}><HeadingBarHeader>R</HeadingBarHeader></Grid>
                <Grid item xs={1}><HeadingBarHeader tooltip="Knowledge">K</HeadingBarHeader></Grid>
                <Grid item xs={2}><HeadingBarHeader></HeadingBarHeader></Grid>
            </Grid>
        </HeadingBar>
        <SimpleTable entries={tableList.map(r => {
            return <CollapseClickAway collapseContent={r.details}>
                <Grid container>
                    <GridRow gridSize={1}>{r.icon}</GridRow>
                    <GridRow gridSize={5}><Typography style={{ textDecoration: r.details !== undefined ? "underline" : undefined}}>{r.name}</Typography> {(r.amount ?? 0) > 1 && <sup>({r.amount})</sup>}</GridRow>
                    <GridRow gridSize={1}>{r.slots}</GridRow>
                    <GridRow gridSize={1}>{r.influence}</GridRow>
                    <GridRow gridSize={1}>{r.resources}</GridRow>
                    <GridRow gridSize={1}>{r.knowledge}</GridRow>
                    <GridRow gridSize={2} justifyContent="flex-end">{r.actions}</GridRow>
                </Grid>
            </CollapseClickAway>
        })} />
    </Section>
}

export default EquipmentList;