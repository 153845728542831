import { MenuItem, Select, SelectChangeEvent, SelectProps, styled } from "@mui/material";

interface SelectFieldProps {
    options: { value: string, name: string }[],
    value: string,
    onChange: (value: string) => void;
    disabled?: boolean;
}

const BlockSelect = styled(Select<string>)<SelectProps<string>>(({ theme }) => ({
    height: "33px",
    border: 0,
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& .MuiSelect-select, & .MuiOutlinedInput-notchedOutline": {
        border: 0,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.dark
    }

}));

const SelectField = (props: SelectFieldProps) => {
    const handleChange = (event: SelectChangeEvent) => {
        props.onChange(event.target.value);
    };

    return <BlockSelect value={props.value} onChange={handleChange}>
        {props.options.map((option) => {
            return <MenuItem value={option.value} >{option.name}</MenuItem>
        })}
    </BlockSelect>
}

export default SelectField;