import { Box, styled } from "@mui/material"
import { Modal } from "../../../components/Modal"
import twifarImage from '../../../images/species/Avatar_Twi_Far.jpg'

export const DeathModal = (props: {
    open: boolean,
    onClose: () => void,
}) => {
    return <Modal
        actions={[
            {
                label: "Darn!",
                size: 12,
                onClick: () => props.onClose()
            }
        ]}
        onClose={() => props.onClose()}
        open={props.open}
        title="YOU'RE DEAD!"
        headerImage={<img src={twifarImage} />}
    >
        <Box paddingLeft={1}>
            <List>
                <li>Gained from a -5 attribute.</li>
            </List>
        </Box>
    </Modal>
}

const List = styled('ul')({
    margin: 0,
    padding: 0,
    listStylePosition: "inside"
})