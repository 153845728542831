import { useContext } from "react"
import { PlayerCharacterDisplay } from "../../model/character"
import { Grid, Typography, useTheme } from "@mui/material"

import { CharacterContext } from "../../model/characterContext";
import { AcquisitionRolls, ArcaneRolls, GritReroll} from "../../model/statsConstant";
import { LabelBoxHeader } from "../../components/LabelBox";

export interface SubStatsDisplayProps {
    character: PlayerCharacterDisplay
}

const RollsDisplay = (props: SubStatsDisplayProps) => {
    const characterContext = useContext(CharacterContext)!;



    const gritReroll = (props.character.attributes.find(a => a.id == "grit")?.current ?? 0) + characterContext.getAdjustment(GritReroll.id)
    const gritCurrent = gritReroll;
    const acquisitionRolls = characterContext.getAdjustment(AcquisitionRolls.id);
    const arcaneRolls = characterContext.getAdjustment(ArcaneRolls.id);
    
    const stats = [
        { label: ArcaneRolls.name, value: `+${arcaneRolls}` },
        { label: AcquisitionRolls.name, value: `+${acquisitionRolls}` },
        { label: "Grit Re-Roll", value: `${gritCurrent} / ${gritReroll}` },
    ]

    return <Grid marginTop={1} container spacing={1}>
        {stats.map((s, index) => {
            return <Grid key={index} item xs={4} sm={4}>
                <LabelBoxHeader>{s.label}</LabelBoxHeader>
                <Typography fontSize={20} textAlign={"center"}>{s.value}</Typography>
            </Grid>;
        })}
    </Grid>
}

export default RollsDisplay;