import { Checkbox, Grid } from "@mui/material";
import { Modal } from "../../../components/Modal";
import { PlayerCharacter } from "../../../model/character";
import { useContext } from "react";
import { CharacterContext } from "../../../model/characterContext";

export interface PostCombatModalProps {
    open: boolean;
    onClose: () => void;
    setCharacter: (haracter: PlayerCharacter) => void;
}

const PostCombatModal = (props: PostCombatModalProps) => {
    const characterContext = useContext(CharacterContext);

    const setValue = (value: boolean) => {

    }

    const onAccept = () => {
        props.onClose();
    }
    
    return <Modal
        actions={[
            { label: "Accept", onClick: () => onAccept(), size: 6 },
            { label: "Close", onClick: () => props.onClose(), size: 6 }
        ]}
        onClose={props.onClose}
        open={props.open}
        title="Post Combat"
        >
            <Grid container spacing={1}>
                <Grid item xs={10}>Reload all Munitions</Grid>
                <Grid item xs={2} textAlign="right"><Checkbox value={false} onChange={(event) => setValue(event.target.checked)} /></Grid>
                <Grid item xs={10}>Heal all Endurance</Grid>
                <Grid item xs={2} textAlign="right"><Checkbox value={false} onChange={(event) => setValue(event.target.checked)} /></Grid>
                <Grid item xs={10}>Remove combat Effects</Grid>
                <Grid item xs={2} textAlign="right"><Checkbox value={false} onChange={(event) => setValue(event.target.checked)} /></Grid>
            </Grid>
        </Modal>
}

export default PostCombatModal;