import { ReactNode, useContext, useState } from "react";
import { Modal, ModalAction, ModalContentSection } from "../../../components/Modal"
import { CharacterContext } from "../../../model/characterContext";
import { Box, Grid, Stack, Typography, useTheme, styled, withTheme, Select, MenuItem, Checkbox } from "@mui/material";

import HexagonIcon from '@mui/icons-material/Hexagon';
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";
import { NumberField } from "../../../components/formFields/NumberField";
import { PlayerCharacter, Weapon } from "../../../model/character";

import { ReactComponent as ActionOverwatchIcon } from "../../../components/icons/raw/Icon_Action_Overwatch.svg";
import { ReactComponent as ActionPrepIcon } from "../../../components/icons/raw/Icon_Action_Prep.svg";
import { ReactComponent as ActionRespositionIcon } from "../../../components/icons/raw/Icon_Action_Reposition.svg";
import { ReactComponent as ActionSightedshotIcon } from "../../../components/icons/raw/Icon_Action_Sighted_Shot.svg";
import { ReactComponent as ActionSnapshotIcon } from "../../../components/icons/raw/Icon_Action_Snap_Shot.svg";
import { ReactComponent as ActionTakeaimIcon } from "../../../components/icons/raw/Icon_Action_Take_Aim.svg";

export interface ActionModalProps {
    open: boolean;
    onClose: () => void;
    setCharacter: (character: PlayerCharacter) => void;
}

type ActionType = "None" | "Reposition" | "Snapshot" | "Take Aim" | "Overwatch" | "Sighted Shot" | "Prep"

const ActionModal = (props: ActionModalProps) => {
    const characterContext = useContext(CharacterContext);
    const theme = useTheme();
    const [actionType, setActionType] = useState<ActionType>("None");

    const heldWeapons = characterContext!.characterDisplay.weapons.filter(w => w.isHeld);

    const [ munitions, setMunitions ] = useState(0);
    const [weaponIndex, setWeaponIndex] = useState(0);

    const changeAction = (actionType: ActionType) => {
        setActionType(actionType);
        setMunitions(0);
    }
    
    const onClose = () => {
        setActionType("None");
        props.onClose();
    }
    
    const WeaponLoadout = (props: {
        rangeBonus?: number;
        hitBonus?: number;
    }) => {
        const [distance, setDistance ] = useState(5);

        if(heldWeapons.length == 0) {
            return <Stack spacing={1}>
                    <LineBreak />
                    <Box>No held weapons</Box>
                </Stack>
        }

        const selectedWeapon = heldWeapons[weaponIndex];

        const range = selectedWeapon.range + (props.rangeBonus ?? 0)
        const hitBonus = selectedWeapon.hitBonus + (props.hitBonus ?? 0);

        const rangeLoss = Math.floor(distance / range) * 2;

        const munitionsLeft = 3 - selectedWeapon.munitionsSpent;

        return <Box>
            <Stack spacing={1}>
                <Select value={weaponIndex} onChange={(event) => setWeaponIndex(event.target.value as number)}>
                    {heldWeapons.map((heldWeapon, index) => {
                        return <MenuItem key={index} value={index}>{heldWeapon.name}</MenuItem>
                    })}
                </Select>
            
            <div>n: {selectedWeapon.munitionsSpent}</div>
                <FormFieldLabel label="Distance from you:" justifyContent="end">
                    <NumberField step={1} min={0} value={distance} onChange={(value) => setDistance(value)} />
                </FormFieldLabel>

                <FormFieldLabel label="Attack Roll" justifyContent="end">{selectedWeapon.hitDice + munitions}d6+{hitBonus - rangeLoss}</FormFieldLabel> 
                <FormFieldLabel label="End Dmg" justifyContent="end">{selectedWeapon.enduranceDamage}</FormFieldLabel>
                <FormFieldLabel label="Crit Dmg" justifyContent="end">{selectedWeapon.critDamage}</FormFieldLabel>    
                <LineBreak />
                <FormFieldLabel label={`Munitions (${munitionsLeft}/3)`} justifyContent="end">
                    <NumberField disabled={munitionsLeft === 0} step={1} max={munitionsLeft} min={0} value={munitions} onChange={(value) => setMunitions(value)} />
                </FormFieldLabel>
            </Stack>
        </Box>
    }

    const saveWeapon = (weapon: Weapon) => {
        const character = characterContext!.character;
        const index = character.weapons.findIndex(tg => tg.id === weapon.id);
        const newItems = [...character.weapons];
        
        if(index === -1) {
            newItems.push(weapon);
        } else {
            newItems[index] = weapon;
        }
        props.setCharacter({
            ...character,
            weapons: newItems
        })
    }

    
    const defaultModalAction: ModalAction[] = [
        {
            label: "Close",
            onClick: onClose,
            size: 6,
        },
        {
            label: "Back",
            onClick: () => changeAction("None"),
            size: 6
        },
    ]

    const acceptModalAction = {
        label: "Accept",
        onClick: () => {
            const foundWeapon = characterContext!.character.weapons.find(w => w.id === heldWeapons[weaponIndex].id)
            if(foundWeapon !== undefined){
                const weapon = { ...foundWeapon};
                weapon.munitionsSpent += munitions;
                saveWeapon(weapon)
            }
            onClose();
        },

        primary: true,
        size: 12
    }
    
    if(actionType === "Reposition") {
        const mobility = characterContext!.getAttribute("mob").current;

        const move = mobility + 2;

        return <Modal
            actions={defaultModalAction}
            onClose={onClose}
            open={props.open}
            title="Action: Reposition">
            <Stack spacing={1} >
                <Box paddingLeft={1}>
                    <List>
                        <li><Typography display={"inline"} fontWeight={"bold"}>Move ({mobility}) +2</Typography> or Cover +1.</li>
                    </List>
                </Box>
                <LineBreak />

                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={6} fontWeight={"bold"}><Box paddingLeft={1}>Total move distance</Box></Grid>
                        <Grid item xs={6} textAlign={"center"}>{move}</Grid>
                        <Grid item xs={6}><Box paddingLeft={1}>Current Cover</Box></Grid>
                        <Grid item xs={6} textAlign={"center"}>{characterContext?.character.cover}</Grid>
                    </Grid>
                </div>
            </Stack>
        </Modal>

    }

    if(actionType === "Take Aim") {
        const TakeAimModal = () => {
            const [hitBonus, setHitBonus] = useState(false);
            const [endBonus, setEndBonus] = useState(false);
            const [critBonus, setCritBonus] = useState(false);

            const prepAcceptModalAction = {
                label: "Accept",
                onClick: () => {
                    
                    onClose();
                },
        
                primary: true,
                size: 12
            }

            return <Modal
                actions={[prepAcceptModalAction,...defaultModalAction]}
                onClose={onClose}
                open={props.open}
                title="Action: Take Aim">
                <Stack spacing={1} >
                    <Box paddingLeft={1}>
                        <List>
                            <li><Typography fontWeight={"bold"} display={"inline"}>Next Atttack gains two (do not stack):</Typography></li>
                        </List>
                    </Box>
                    <LineBreak />

                    <Grid container spacing={1}>
                        <Grid item xs={6}>Apply +2 to Hit</Grid>
                        <Grid item xs={6} textAlign={"right"}><Checkbox value={hitBonus} onChange={(event) => setHitBonus(event.target.checked)} /></Grid>
                        <Grid item xs={6}>Apply +2 to End Dmg</Grid>
                        <Grid item xs={6} textAlign={"right"}><Checkbox value={endBonus} onChange={(event) => setEndBonus(event.target.checked)} /></Grid>
                        <Grid item xs={6}>Apply crit location +/-1</Grid>
                        <Grid item xs={6} textAlign={"right"}><Checkbox value={critBonus} onChange={(event) => setCritBonus(event.target.checked)} /></Grid>
                    </Grid>
                </Stack>
            </Modal>
        }
        return <TakeAimModal />
    }

    

    if(actionType === "Prep") {
        const PrepModal = () => {
            const [ reloadSub, setReloadSub ] = useState(false);
            const [ recoverSub, setRecoverSub ] = useState(false);

            const selectedWeapon = heldWeapons.length > 0 ? heldWeapons[weaponIndex] : undefined;

        
            const prepAcceptModalAction = {
                label: "Accept",
                onClick: () => {
                    if(reloadSub) {
                        const foundWeapon = characterContext!.character.weapons.find(w => w.id === heldWeapons[weaponIndex].id)

                        if(foundWeapon !== undefined){
                            const weapon = { ...foundWeapon};
                            weapon.munitionsSpent += munitions;

                            weapon.reloadsPerformed ++;
                            if(weapon.reloadsPerformed >= (selectedWeapon?.reload ?? 1)){
                                weapon.reloadsPerformed = 0;
                                weapon.munitionsSpent = 0;
                            }

                            saveWeapon(weapon)
                        }
                    }
                    if(recoverSub) {

                    }
                    onClose();
                },
        
                primary: true,
                size: 12
            }
    
            return <Modal
                actions={[
                    prepAcceptModalAction,
                    ...defaultModalAction]}
                onClose={onClose}
                open={props.open}
                title="Action: Prep">
                <Stack spacing={1} >
                    <Box paddingLeft={1}>
                        <List>
                            <li><Typography fontWeight={"bold"} display={"inline"}>Select two:</Typography></li>
                            <li>{selectedWeapon?.reloadsPerformed ?? 1}/{selectedWeapon?.reload ?? 1} Reload, Recover (3), Move (3), or Skill Roll.</li>
                        </List>
                    </Box>
    
                    {selectedWeapon !== undefined && <>
                        <Select value={weaponIndex} onChange={(event) => setWeaponIndex(event.target.value as number)}>
                            {heldWeapons.map((heldWeapon, index) => {
                                return <MenuItem key={index} value={index}>{heldWeapon.name}</MenuItem>
                            })}
                        </Select>
                    </>}
                    <Grid container spacing={1}>
                        {selectedWeapon !== undefined && <>
                            <Grid item xs={8}>Apply a Reload</Grid>
                            <Grid item xs={2}>1/{selectedWeapon?.reload ?? 1}</Grid>
                            <Grid item xs={2}><Checkbox value={reloadSub} onChange={(event) => setReloadSub(event.target.checked)} /></Grid>
                        </>}
                        <>
                            <Grid item xs={8}>Apply a Recovery</Grid>
                            <Grid item xs={2}>+2</Grid>
                            <Grid item xs={2}><Checkbox value={recoverSub} onChange={(event) => setRecoverSub(event.target.checked)} /></Grid>
                        </>
                    </Grid>
                    <ModalContentSection title="Skill Roll examples" collapsable={true}>
                        <Box paddingLeft={1}>
                            <Typography fontWeight={"bold"}>Remove Effect:</Typography>
                            <Box>
                                <List>
                                    <li>Skill Roll: 12.</li>
                                    <li>Remove all effects of one type (ie: Bleeding, Burn, Suppresed, etc...) from a character.</li>
                                </List>
                            </Box>

                            <Typography marginTop={1} fontWeight={"bold"}>Stealthed:</Typography>
                            <Box>
                                <List>
                                    <li>Skill Roll: 10 to 18 (Stealth).</li>
                                    <li>You require Cover against all enemies.</li>
                                    <li>Move at 1/2 speed.</li>
                                    <li>May no be perveived or Attacked. You lose Stealthed if you: 'Attack with a Weapon without the 'Invisble Attack' Keyword, You lose Cover against any enemy facing you, An enemy performs a successful Awareness Skill Roll on you or the Locked On effect is applied to you.'</li>
                                </List>
                            </Box>

                            <Typography marginTop={1} fontWeight={"bold"}>Tackle:</Typography>
                            <Box>
                                <List>
                                    <li>Skill Roll: 10 to 18 (Physical).</li>
                                    <li>May Supress both characters during their next Turn.</li>
                                    <li>Winner may also move the other characters a number of space equal to 'their Strength - their opponent's Strength' (+/-2 distance if Perk: Tiny/Big).</li>
                                    <li>If suitable to the situation, the GM may declare your opponent completely incapacitated while you remain adjacent to them.</li>
                                </List>
                            </Box>
                        </Box>
                    </ModalContentSection>
                </Stack>
            </Modal>
        }
        return <PrepModal />
    }

    
    if(actionType === "Overwatch") {
        const focus = characterContext!.getAttribute("foc").current;

        return <Modal
            actions={[
                acceptModalAction,
                ...defaultModalAction,
            ]}
            onClose={onClose}
            open={props.open}
            title="Action: Overwatch">
            <Stack spacing={1} >
                <Box paddingLeft={1}>
                    <List>
                        <li><Typography fontWeight={"bold"} display={"inline"}>Attack in response to any Action.</Typography></li>
                        <li>Range: +Focus ({focus}).</li>
                    </List>
                </Box>
                <WeaponLoadout rangeBonus={focus} />
            </Stack>
        </Modal>
    }

    if(actionType === "Snapshot") {
        const mobility = characterContext!.getAttribute("mob").current;

        return <Modal
            actions={[
                acceptModalAction,
                ...defaultModalAction,
            ]}
            onClose={onClose}
            open={props.open}
            title="Action: Snap Shot">
            <Stack spacing={1} >
                <Box paddingLeft={1}>
                    <List>
                        <li><Typography fontWeight={"bold"} display={"inline"}>Attack.</Typography></li>
                        <li>Move ({mobility}).</li>
                    </List>
                </Box>
                <WeaponLoadout />
            </Stack>
        </Modal>
    }

    if(actionType === "Sighted Shot") {
        const focus = characterContext!.getAttribute("foc").current;
        const reflexes = characterContext!.getAttribute("ref").current;

        return <Modal
            actions={[
                acceptModalAction,
                ...defaultModalAction,
            ]}
            onClose={onClose}
            open={props.open}
            title="Action: Sighted Shot">
            <Stack spacing={1} >
                <Box paddingLeft={1}>
                    <List>
                        <li><Typography fontWeight={"bold"} display={"inline"}>Attack.</Typography></li>
                        <li>Hit +Reflexes ({reflexes}).</li>
                        <li>Range +Focus ({focus}).</li>
                    </List>
                </Box>
                <WeaponLoadout rangeBonus={focus} hitBonus={reflexes} />
            </Stack>
        </Modal>
    }

    return <Modal actions={[
        {
            label: "Close",
            onClick: onClose,
            primary: true,
            size: 12,
        }
    ]} 
        onClose={onClose}
        open={props.open}
        title="Actions (2 per turn)">
        <Grid container spacing={1}>
            <Grid item xs={4}><ActionButton label="Reposition" onClick={() => changeAction("Reposition")} icon={<ActionRespositionIcon fill={theme.palette.primary.contrastText} />} /></Grid>
            <Grid item xs={4}><ActionButton label="Snapshot" onClick={() => changeAction("Snapshot")} icon={<ActionSnapshotIcon fill={theme.palette.primary.contrastText} />} /></Grid>
            <Grid item xs={4}><ActionButton label="Take Aim" onClick={() => changeAction("Take Aim")} icon={<ActionTakeaimIcon fill={theme.palette.primary.contrastText} />} /></Grid>
            <Grid item xs={4}><ActionButton label="Overwatch" onClick={() => changeAction("Overwatch")} icon={<ActionOverwatchIcon fill={theme.palette.primary.contrastText} />} /></Grid>
            <Grid item xs={4}><ActionButton label="Sighted Shot" onClick={() => changeAction("Sighted Shot")} icon={<ActionSightedshotIcon fill={theme.palette.primary.contrastText} />} /></Grid>
            <Grid item xs={4}><ActionButton label="Prep" onClick={() => changeAction("Prep")} icon={<ActionPrepIcon fill={theme.palette.primary.contrastText} />} /></Grid>
        </Grid>
    </Modal>
}

const ActionButton = (props: { label: string, icon: ReactNode, onClick: () => void}) => {
    return <ActionButtonBox onClick={props.onClick} tabIndex={0}>
        <Hexagon>
            <HexagonInner>
                <Box fontSize="55px"><HexagonIcon fontSize="inherit" color="primary" /><HexagonInner>{props.icon}</HexagonInner></Box>
            </HexagonInner>
        </Hexagon>
        <Box textAlign={"center"} fontWeight={"bold"}>
            {props.label}
        </Box>
    </ActionButtonBox>
}

const ActionButtonBox = styled(Box)({
    cursor: "pointer",
    '&: hover': {
        backgroundColor: '#00000011'
    }
})

const LineBreak = styled('div')(props => ({
    width: '100%',
    borderBottom: `2px solid ${props.theme.palette.primary.main}`,
}))

const Hexagon = styled('div')`
    width: 55px;
    height: 55px;
    margin: 0 auto;
    position: relative;
`

const HexagonInner = styled('div')`
    position: absolute;
    top:7px;
    left:7px;
    right:7px;
    bottom:7px;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff
`;

const List = styled('ul')({
    margin: 0,
    padding: 0,
    listStylePosition: "inside"
})

export default ActionModal;