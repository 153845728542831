import { Box, Grid, GridSize, Typography, styled } from "@mui/material";
import { ReactNode } from "react";

const Label = styled('div')`
    text-wrap: nowrap;
`;

const FormFieldLabel = (props: {
    children?: ReactNode,
    label: string,
    labelSize?: GridSize,
    justifyContent?: "center" | "start" | "end"
}) => {
    return <Box height="33px"><Grid container alignItems="center" spacing={2} height="33px">
        <Grid item xs={props.labelSize ?? 6} md={props.labelSize ?? 4}>
            <Box paddingLeft={1}><Label>{props.label}</Label></Box>
        </Grid>
        <Grid item xs display="flex" justifyContent={props.justifyContent}>{props.children}</Grid>
    </Grid></Box>
}

export default FormFieldLabel;