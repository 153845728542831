import { Box, Checkbox, Grid, styled, useTheme } from "@mui/material";
import { Modal } from "../../../components/Modal";
import { PlayerCharacter } from "../../../model/character";
import { useContext, useEffect, useState } from "react";
import { CharacterContext } from "../../../model/characterContext";
import { LabelBoxHeader } from "../../../components/LabelBox";

import { ReactComponent as EnduranceDamageIcon } from "../../../components/icons/raw/Icon_End_Damage.svg";
import ProgressBar from "../../../components/ProgressBar";

export interface PostSessionModalProps {
    open: boolean;
    onClose: () => void;
    setCharacter: (character: PlayerCharacter) => void;
}

const PostSessionModal = (props: PostSessionModalProps) => {
    const characterContext = useContext(CharacterContext);
    const theme = useTheme();

    const [ sessionCount, setSessionCount] = useState(true);
    const [ spareTime, setSpareTime] = useState(false);
    const [ gritReroll, setGritReroll] = useState(false);

    useEffect(() => {
        if(props.open){
            setSessionCount(true);
            setSpareTime(false);
            setGritReroll(false);
        }
    }, [ props.open]);

    const onAccept = () => {
        props.setCharacter({
            ...characterContext!.character,
            competence: characterContext!.character.competence + (sessionCount ? 1 : 0),
            gritRerollSpent: gritReroll ? 0 : characterContext!.character.gritRerollSpent
        });

        props.onClose();
    }
    
    return <Modal
        actions={[
            { label: "Accept", onClick: () => onAccept(), size: 6 },
            { label: "Close", onClick: () => props.onClose(), size: 6 }
        ]}
        onClose={props.onClose}
        open={props.open}
        title="Post Session"
        >
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={8}>Increase Session Count</Grid>
                <Grid item xs={2}>+1</Grid>
                <Grid item xs={2} textAlign="right"><Checkbox value={sessionCount} onChange={(event) => setSessionCount(event.target.checked)} /></Grid>
                <Grid item xs={12}>
                    <ProgressBar 
                        amount={1/3*100}
                        label={<><EnduranceDamageIcon fill={theme.palette.primary.contrastText} height="1.2em" /><Box marginLeft="4px">Current Sessions</Box></>}
                        barColor="#00db5f"
                        barLabel="1/3"
                    />
                </Grid>
                <Grid item xs={8}>Gain a Spare Time Point</Grid>
                <Grid item xs={2}>+1</Grid>
                <Grid item xs={2} textAlign="right"><Checkbox value={spareTime} onChange={(event) => setSpareTime(event.target.checked)} /></Grid>
                <Grid item xs={10}>Regain Grit Re-Roll Points</Grid>
                <Grid item xs={2} textAlign="right"><Checkbox disableRipple value={gritReroll} onChange={(event) => setGritReroll(event.target.checked)} /></Grid>
            </Grid>
        </Modal>
}

export default PostSessionModal;