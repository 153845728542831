import { Box, styled } from "@mui/material";
import { LabelBoxHeader } from "./LabelBox";
import { ReactNode } from "react";
import { Padding } from "@mui/icons-material";

const OuterBox = styled('div')(({ theme }) => ({
    padding: "4px",
    backgroundColor: theme.palette.primary.main,
    flexGrow: 1,
    height: "100%"
}));
    
const InnerBox = styled('div')`
    position: relative;
    height:100%;
    background: #fff;
`;

const Label = styled('div')`
    text-align: center;
    color: #000;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    font-size:
    line-height: 1em;
`;

const Bar = styled('div')<{ amount: number, fillColor?: string }>`
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    width: ${props => props.amount}%;
    background-color: ${props => props.fillColor ?? "#dd0000"};
`;

interface ProgressBarProps {
    amount: number;
    label: ReactNode;
    barLabel?: string;
    barColor?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
    return <>
        <Box height="28px" display="flex">
            <OuterBox>
                <InnerBox>
                    <Bar amount={props.amount} color={props.barColor} />
                    <Label>{props.barLabel}</Label>
                </InnerBox>
            </OuterBox>
        </Box>
        <LabelBoxHeader>{props.label}</LabelBoxHeader>
    </>;
}

export default ProgressBar;