import { Box, Grid, Typography, useTheme, styled, Button } from "@mui/material"
import { Cover, PlayerCharacter, PlayerCharacterDisplay } from "../../model/character";
import { CharacterContext } from "../../model/characterContext";
import { ReactNode, useContext } from "react";
import { ArmorAtZeroStat, ArmorStat, Defence, EnduranceMaxStat, GritReroll, Recovery } from "../../model/statsConstant";
import ButtonFlat from "../../components/ButtonFlat";

import { ReactComponent as EnduranceDamageIcon } from "../../components/icons/raw/Icon_End_Damage.svg";
import { ReactComponent as ResistanceIcon } from "../../components/icons/raw/Icon_Resistance.svg";
import { ReactComponent as ArmourIcon } from "../../components/icons/raw/Icon_Armour.svg";
import { ReactComponent as DefenceIcon } from "../../components/icons/raw/Icon_Defence.svg";
import LabelBox, { LabelBoxHeader } from "../../components/LabelBox";
import ProgressBar from "../../components/ProgressBar";

const CoverButton = styled(Button)<{ selected: boolean }>((props) => ({
    backgroundColor: props.selected ? "#cc1e1e" : "#dedede",
    color: "#ffffff",
    padding: 0,
    margin: 0,
    minWidth: 0,
    borderRadius: 0,
    height: "30px",
    fontWeight: "bold",
    textTransform: "none",
}))

interface EnduranceDamageViewProps {
    character: PlayerCharacter;
    characterDisplay: PlayerCharacterDisplay;
    setDamage: (value: number) => void;
    setCover: (value: Cover) => void;
}

const CombatStatsView = (props: EnduranceDamageViewProps) => {
    const characterContext = useContext(CharacterContext)!;
    const theme = useTheme();
    const { character, characterDisplay, setDamage, setCover } = props;

    const strength = characterDisplay.attributes.find(a => a.id == "str")?.withDamage ?? 0;

    const enduranceMax = 10 + (strength ?? 0) * 5 + characterContext.getAdjustment(EnduranceMaxStat.id);
    const endurance = enduranceMax - (character.enduranceDamageTaken ?? 0);
    const recovery = (characterDisplay.attributes.find(a => a.id == "grit")?.current ?? 0) + characterContext.getAdjustment(Recovery.id); // Change to current

    const grit = characterDisplay.attributes.find(a => a.id == "grit")?.withDamage ?? 0;
    const gritReroll = grit + characterContext.getAdjustment(GritReroll.id)

    const defence = 10 + (characterDisplay.attributes.find(a => a.id == "ref")?.current ?? 0) + characterContext.getAdjustment(Defence.id); // Change to current
    let armour = (characterDisplay.outfits.find(outfit => outfit.isWorn)?.armor ?? 0) + characterContext.getAdjustment(ArmorStat.id);
    let armourAtZero = (characterDisplay.outfits.find(outfit => outfit.isWorn)?.armorAtZero ?? 0) + characterContext.getAdjustment(ArmorAtZeroStat.id);
    

    const adjustDamage = (amount: number) => {
        setDamage(Math.min(Math.max(character.enduranceDamageTaken + amount, 0), enduranceMax));
    }
    
    return <>
        <Box>
            <Grid marginBottom={1} container spacing={1}>
                <Grid item xs={4} sm={4}>
                    <LabelBox label={<><DefenceIcon fill={theme.palette.primary.contrastText} height="1.2em" /> Defence</>}>
                        <Typography fontSize={20} textAlign={"center"}>{defence}</Typography>
                    </LabelBox>
                    
                </Grid>
                <Grid item xs={4} sm={4}>
                    <LabelBox label="Cover">
                        <Grid spacing={"4px"} container>
                            <Grid item xs={3}><CoverButton fullWidth selected={character.cover === "None"} onClick={() => setCover("None")}>N</CoverButton></Grid>
                            <Grid item xs={3}><CoverButton fullWidth selected={character.cover === "Light"} onClick={() => setCover("Light")}>Lt</CoverButton></Grid>
                            <Grid item xs={3}><CoverButton fullWidth selected={character.cover === "Heavy"} onClick={() => setCover("Heavy")}>Hv</CoverButton></Grid>
                            <Grid item xs={3}><CoverButton fullWidth selected={character.cover === "Entrenched"} onClick={() => setCover("Entrenched")}>Ent</CoverButton></Grid>
                        </Grid>
                    </LabelBox>
                </Grid>
                <Grid item xs={4} sm={4}>
                    <LabelBox label={<div><ArmourIcon fill={theme.palette.primary.contrastText} height="1.2em" />    Armour</div>}>
                        <Typography fontSize={20} textAlign={"center"}>{armour} ({armourAtZero} at 0 End)</Typography>
                    </LabelBox>
                </Grid>
                <Grid item xs={8} sm={8}>
                    <ProgressBar
                        amount={endurance/enduranceMax*100}
                        barLabel={`${endurance}/${enduranceMax} (${recovery})`}
                        label={<><EnduranceDamageIcon fill={theme.palette.primary.contrastText} height="1.2em" /><Box marginLeft="4px">Endurance</Box> (Recovery)</>} />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <LabelBox label={<><ResistanceIcon fill={theme.palette.primary.contrastText} height="1.2em" /> Resistance</>}>
                        <Typography fontSize={20} textAlign={"center"}>-</Typography>
                    </LabelBox>
                </Grid>
            </Grid>
        </Box>
    </>
}

export default CombatStatsView;