import { Box, Breakpoint, Button, Collapse, Dialog, DialogActions, DialogContent, Grid, GridSize, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ReactNode, useState } from "react"

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ButtonFlat from "./ButtonFlat";

export interface ModalAction {
    label: string;
    onClick: () => void;
    primary?: boolean;
    icon?: ReactNode;
    size?: GridSize;
}

interface ModalContentSectionProps {
    children?: ReactNode;
    title: string;
    collapsable?: boolean;
    onAdd?: () => void;
}

const HeaderButton = (props: { action: () => void, children?: ReactNode}) => {
    return <Button style={{ borderRadius: 0, boxShadow: "none", padding: 0, minWidth: "unset", aspectRatio: "1/1"}} variant="contained" onClick={() => props.action()}>{props.children}</Button>
}

export const ModalContentSection = (props: ModalContentSectionProps) => {
    const [ collapse, setCollapse ] = useState(false);
    const { children, title, collapsable, onAdd } = props;
    const theme = useTheme();

    return <div>
        <Stack direction="row" marginTop={1} spacing={1} height={"33px"}>
            <Box height="33px" flexGrow={1} sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}} display={"flex"} alignItems={"center"} paddingLeft={1}>
                <Typography margin={0} flexGrow={1} fontWeight={400}>{title}</Typography>
            </Box>
            {onAdd !== undefined && <HeaderButton action={onAdd}><AddIcon /></HeaderButton>}
            {collapsable === true && <HeaderButton action={() => setCollapse(!collapse)}>{collapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}</HeaderButton>}
        </Stack>
        <Collapse in={!collapse}>
            <Box paddingTop={1}>
                {children}
            </Box>
        </Collapse>
    </div>
}

export interface ModalProps {
    open: boolean;
    title?: string;
    fullscreen?: boolean;
    onClose: () => void;
    actions: ModalAction[];
    children?: ReactNode;
    maxWidth?: Breakpoint;
    headerImage?: ReactNode;
}

export const Modal = (props: ModalProps) => {
    const theme = useTheme();

    const { open, title, onClose, actions, children, maxWidth,headerImage } = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <Dialog
        maxWidth={maxWidth ?? "sm"} fullWidth fullScreen={fullScreen} open={open} onClose={onClose}>
        { title !== undefined && <Box padding={1} paddingLeft={3} fontWeight={700}
            sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                }}>{title}</Box>}
    { headerImage }
    { children && <DialogContent sx={{ padding: "16px"}}>
            {children}
    </DialogContent>}
    <DialogActions sx={{ padding: "16px"}}>
        <Grid container spacing={1}>
            {actions.map((action, index) => {
                return <Grid item xs={action.size ?? 6}><ButtonFlat fullWidth key={index} variant={action.primary ? "contained" : "outlined"} onClick={action.onClick}>{action.label}</ButtonFlat></Grid>;
                })}
        </Grid>
    </DialogActions>
</Dialog>
}