import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";

export const LabelBoxHeader = (props: {children?: ReactNode}) => {
    const theme = useTheme();
    return <Box height="26px" style={{ 
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        display: "flex", alignItems: "center", justifyContent: "center", fontSize: 12, lineHeight: "1em"}}>{props.children}</Box>
}

const LabelBox = (props: {children?: ReactNode, label: ReactNode}) => {
    return <>
        <LabelBoxHeader>{props.label}</LabelBoxHeader>
        <Box marginTop={1} height={"30px"}>{props.children}</Box>
    </>
}

export default LabelBox;